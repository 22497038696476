/* eslint-disable @typescript-eslint/no-unused-vars */
import { definition, EntityMeta, getStandardApi, getStandardUrls, T } from 'core/module';

import { CostCategoryGrid, CostCategory } from './models/CostCategory';
import { CostCluster, CostClusterGrid } from './models/CostCluster';
import { Label, LabelGrid } from './models/Label';

export const listEntities = {
  costCluster: {
    ...definition<CostClusterGrid, CostCluster>('costs/clusters', {
      name: (t: T) => t('Cost Clusters'),
      listName: (t: T) => t('Cost Clusters'),
      permission: 'costs',
    }),
    urls: (_?: string) => ({
      ...getStandardUrls('cost-clusters'),
    }),
    api: () => ({
      ...getStandardApi('costs/clusters'),
    }),
  },
  costCategory: {
    ...definition<CostCategoryGrid, CostCategory>('costs/categories', {
      name: (t: T) => t('Cost Categories'),
      listName: (t: T) => t('Cost Categories'),
      permission: 'costs',
    }),
    urls: (_?: string) => ({
      ...getStandardUrls('cost-categories'),
    }),
    api: () => ({
      ...getStandardApi('costs/categories'),
    }),
  },
  label: {
    ...definition<LabelGrid, Label>('labels', {
      name: (t: T) => t('Labels'),
      listName: (t: T) => t('Labels'),
      permission: 'labels',
    }),
    urls: (_?: string) => ({
      ...getStandardUrls('labels'),
    }),
    api: () => ({
      ...getStandardApi('labels'),
    }),
  },
} as const;

// REQUIRED !!!
// @ts-ignore
const verifyEntities: Record<keyof typeof listEntities, EntityMeta> = listEntities;

export type K = keyof typeof listEntities;
